<template>
  <v-data-table
    :items="filteredLeads"
    loading-text="A carregar..."
    :headers="headers"
    :loading="loaderDataTable"
    :items-per-page="itemsPerPage"
    :hide-default-footer="hideDefaultFooter"
  >
    <template v-slot:item.editar="{ item }">
      <LeadInsertData :lead="item" @update-made="collect_leads" />
    </template>
    <template v-slot:item.detalhes="{ item }">
      <LeadDetail :lead="item" />
    </template>
  </v-data-table>
</template>

<script>
import LeadDetail from "@/components/Lead_Detail";
import LeadInsertData from "@/components/Lead_Insert_Data";

export default {
  name: "DataTable",
  props: [
    {
      filterEstado: Object,
      require: true,
    },
    {
      headers: Object,
      require: true,
    },
    {
      lead: Object,
      require: true,
    },
    {
      loaderDataTable: Boolean,
      default: false,
    },
    {
      hideDefaultFooter: Boolean,
      default: false,
    },
    {
      itemsPerPage: Number,
      default: 5,
    },
  ],
  components: { LeadDetail, LeadInsertData },
  data() {
    return {
      moment: require("moment"),
    };
  },
  methods: {
    collect_leads: async function () {
      await this.$store.dispatch("collect_leads_enabled");
    },
    separateProduct(word) {
      let compareProduto = word.includes("_")
        ? word.split("_")
        : word.split(" ");
      compareProduto = `${compareProduto[0][0]}${compareProduto[1][0]}`.toLowerCase();

      return compareProduto;
    },
    filter: function (item) {
      let typeOfViewMode = this.$store.state.typeOfViewMode;
      var format = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/áàâäãéèêëíîìïóòôöúùûõ?~]/;

      let test = this.filterEstado.split("-");

      let last = test.length - 1;
      test = test[last];
      let indexCut = Math.floor((2 * (test.length - 1)) / 5);

      if (format.test(test)) {
        test = test.split("");
        test = test.slice(0, indexCut).join("");
      }

      let dates = this.$store.state.report_dates;
      let compareProduto;
      if (typeOfViewMode == "Produto") {
        compareProduto = this.separateProduct(this.lead.produto);
      }

      switch (typeOfViewMode) {
        case "Consultor":
          return (
            item.current_step.toLocaleLowerCase().includes(test) &&
            item.username == this.lead.consultor &&
            new Date(`${dates[0]} 00:00:00`) <=
              new Date(item.last_interation) &&
            new Date(`${dates[1]} 23:59:59`) >= new Date(item.last_interation)
          );
        case "Diário":
          return (
            item.current_step.toLocaleLowerCase().includes(test) &&
            item.last_interation.split(" ")[0] == this.lead.last_interation
          );
        case "Semanal":
          return (
            item.current_step.toLocaleLowerCase().includes(test) &&
            this.moment(item.last_interation.split(" ")[0]).week() ==
              this.lead.week
          );
        case "Mensal":
          return (
            item.current_step.toLocaleLowerCase().includes(test) &&
            this.moment(item.last_interation.split(" ")[0]).week() ==
              this.lead.month
          );
        case "Produto":
          return (
            item.current_step.toLocaleLowerCase().includes(test) &&
            this.separateProduct(item.produto) == compareProduto
          );
      }
    },
  },
  computed: {
    filteredLeads() {
      let map1 = [];

      let test = this.filterEstado.split("-");
      let last = test.length - 1;
      test = test[last].split("");
      let indexCut = Math.floor((2 * (test.length - 1)) / 5);
      test = test.slice(0, indexCut).join("");

      if (this.$store.state.leads_enabled.length > 0) {
        map1 = this.$store.state.leads_enabled.filter(this.filter);
      }

      let map2 = [];

      if (this.$store.state.leads_disabled.length > 0) {
        map2 = this.$store.state.leads_disabled.filter(this.filter);
      }

      return map1.concat(map2);
    },
  },
};
</script>

<style>
</style>