var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex align-end"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.viewModes,"label":"Modo de Visualização","rules":_vm.rulesText},on:{"change":_vm.typeOfLead},model:{value:(_vm.viewMode),callback:function ($$v) {_vm.viewMode=$$v},expression:"viewMode"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"close-on-content-click":false,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Selecionar intervalo de datas","prepend-icon":"event","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"scrollable":"","range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.date);
                _vm.configureDates(_vm.date);}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.produtos,"item-text":"name","item-value":"id","label":"Produto","attach":"","rules":_vm.rulesText},model:{value:(_vm.produto),callback:function ($$v) {_vm.produto=$$v},expression:"produto"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"3"}},[_c('v-select',{attrs:{"items":_vm.consultores,"item-text":"name","item-value":"id","label":"Consultor","attach":"","chips":"","multiple":"","rules":_vm.rulesText},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [(index < 2)?_c('v-chip',[_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-spacer')],1):_vm._e(),(index == 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" "+_vm._s(("+" + (_vm.consultor.length - 2) + " other" + (_vm.consultor.length - 2 > 1 ? "s" : "")))+" ")]):_vm._e()]}}]),model:{value:(_vm.consultor),callback:function ($$v) {_vm.consultor=$$v},expression:"consultor"}})],1),_c('v-col',{staticClass:"d-flex align-self-center",attrs:{"cols":"12","sm":"1"}},[_c('v-spacer'),_c('v-fade-transition',[_c('v-btn',{attrs:{"dark":"","disabled":_vm.produto == '' || _vm.consultor == '' || _vm.viewMode == '' || _vm.date == ''},on:{"click":function($event){return _vm.generateReport()}}},[_vm._v(" Gerar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }