<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on }">
        <p v-on="on" class="p_slotTable">
          {{ lead[Object.keys(lead)[totalOfData]] }}
        </p>
      </template>

      <v-card>
        <v-toolbar dark color="#c4c22c">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title></v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <!-- TABELA: -->

        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <DataTable
                  :filterEstado="order"
                  :lead="lead"
                  :headers="user_level > 1 ? headersAdmin : headersAgent"
                  :loaderDataTable="loaderDataTable"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import DataTable from "@/components/DataTable";

export default {
  name: "SlotDataTable",
  props: ["lead", "order"],
  components: {
    DataTable,
  },
  data() {
    return {
      dialog: false,
      headersAdmin: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        {
          text: "Mediador",
          align: "center",
          value: "username",
          sortable: true,
        },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Ãšltimo Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
        { text: "Editar", align: "center", value: "editar", sortable: false },
      ],
      headersAgent: [
        {
          text: "ID",
          align: "left",
          value: "lead_process_id",
        },
        // { text: 'Data', align: 'center', value: 'dt_created', sortable:true },
        { text: "Nome", align: "center", value: "nome", sortable: true },
        { text: "Email", align: "center", value: "email", sortable: true },
        {
          text: "Telefone",
          align: "center",
          value: "telefone",
          sortable: true,
        },
        { text: "Montante", align: "center", value: "aux_1", sortable: true },
        // { text: 'Prazo', align: 'center', value: 'aux_2', sortable:true },
        {
          text: "Ãšltimo Contacto",
          align: "center",
          value: "last_interation",
          sortable: true,
        },
        {
          text: "Agendamento",
          align: "center",
          value: "callback_date",
          sortable: true,
        },
        {
          text: "Estado",
          align: "center",
          value: "current_step",
          sortable: true,
        },
        {
          text: "Detalhes",
          align: "center",
          value: "detalhes",
          sortable: true,
        },
        { text: "Editar", align: "center", value: "editar", sortable: false },
      ],
      user_level: window.sessionStorage.getItem("user_level"),
    };
  },
  computed: {
    totalOfData: function () {
      if (this.order != undefined) {
        let test = this.order.split("-")[1].split("");
        let indexCut = Math.floor((3 * (test.length - 1)) / 5);
        test = test.slice(0, indexCut).join("");

        let index = Object.keys(this.lead).findIndex((e) =>
          e.toLocaleLowerCase().includes(test)
        );

        return index;
      } else return 0;
    },
    loaderDataTable() {
      if (
        !this.$store.state.doneCallLeadsDisabled ||
        !this.$store.state.doneCallLeadsEnable
      )
        return true;
      else return false;
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin-left: 40px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}

h2 {
  margin-left: 40px;
  color: black;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  display: block;
}
.h2-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  margin-bottom: -6px;
}

.p_slotTable {
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    color: rgb(196, 194, 44);
    font-weight: 800;
  }
}

.container {
  margin: 10px 0px;
  max-width: 100%;
}

.colunas {
  padding: 20px 0;
  margin-top: 10px;
}

.colunas-interiores {
  padding: 20px 40px;
}

.v-list-item {
  min-height: 10px;
  padding: 0;
  margin-bottom: 0 !important;
}

.v-list-item__content {
  padding: 8px 0;
}

.v-list-item__title {
  font-size: 14px;
}

h3 {
  font-size: 14px;
  margin-bottom: 10px;
}
.v-item-group {
  padding: 0 30px;
}
.v-list-item__icon {
  margin-right: 10px !important;
  margin-left: 20px;
}

.timeline-date {
  font-size: 14px;
  padding-bottom: 5px !important;
}

.timeline-container {
  margin-top: 20px;
  padding-right: 25px;
}

.sub-titulos-ficha {
  margin-left: 0px;
  margin-bottom: 5px;
}

.sub-titulo-bold {
  text-transform: capitalize;
}
</style>
   