
<template>
  <div>
    <h1>Reports<span class="h1-underline"></span></h1>

    <!-- FILTER REPORT -->
    <FiltersReport @collect-leads="collectLeads" />

    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <!-- <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field> -->
      </v-card-title>
      <!-- TABELA: -->
      <v-data-table
        :headers="headers"
        :items="dadosFinais"
        :loading="loading"
        loading-text="A carregar..."
      >
        <!-- VIEW DATA TABLE  -->
        <template v-slot:body="props">
          <tr v-for="(index, j) in props.items" :key="j">
            <td v-for="(header, i) in headers" :key="i">
              <!-- TEXT: -->
              <p v-if="viewModes.includes(header.value)">
                {{
                  index[header.value].toString().includes("_")
                    ? index[header.value].toString().split("_").join(" ")
                    : index[header.value]
                }}
              </p>

              <!-- DATA TABLE -->
              <SlotDataTable v-else :lead="index" :order="header.value" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-btn
      :loading="loading5"
      :disabled="loading5"
      color="blue-grey"
      class="ma-2 white--text"
      fab
      @click="export_excel(dadosFinais)"
    >
      <v-icon dark>mdi-cloud-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { json2excel } from "js2excel";
import FiltersReport from "@/components/Filters_Report";
import SlotDataTable from "@/components/SlotDataTable";

export default {
  components: {
    FiltersReport,
    SlotDataTable,
  },
  name: "Reports",
  data() {
    return {
      search: "",

      headers: [],
      dados: [],

      loader: null,
      loading: false,
      loading2: false,
      loading3: false,
      loading4: false,
      loading5: false,
      viewModes: [
        "month",
        "consultor",
        "last_interation",
        "total",
        "week",
        "produto",
      ],

      modo: "",
      produto: "",
    };
  },
  computed: {
    dadosFinais() {
      let res = [];
      let propriedade = "";

      if (this.headers == [] || this.dados == []) {
        return [];
      } else {
        let modos = {
          Diário: "last_interation",
          Semanal: "week",
          Mensal: "month",
          Produto: "produto",
          Consultor: "consultor",
        };

        propriedade = modos[this.modo];

        let temp = this.dados.reduce((acc, obj) => {
          let key = obj[propriedade];
          if (!acc[key]) {
            acc[key] = {};
            acc[key][obj.process_step] = 0;
            acc[key]["total"] = 0;
            acc[key][propriedade] = obj[propriedade];
          } else if (!acc[key][obj.process_step]) {
            acc[key][obj.process_step] = 0;
          }

          acc[key][obj.process_step] =
            parseInt(acc[key][obj.process_step]) + parseInt(obj.num_leads);
          acc[key]["total"] = parseInt(acc[key]["total"]) + parseInt(obj.total);

          return acc;
        }, {});

        res = Object.values(temp);
      }

      return res;
    },
  },

  methods: {
    export_excel: async function (data) {
      let app = this;
      try {
        json2excel({
          data,
          name: `report_${app.startDT}_a_${app.endDT}`,
          formateDate: "yyyy-mm-dd",
        });
      } catch (e) {
        alert("export error: " + e);
      }
    },
    async collectLeads(filters) {
      this.loading = true;

      this.startDT = filters.start_date.toString().slice(0, 10);
      this.endDT = filters.end_date.toString().slice(0, 10);

      this.modo = filters.modo;
      this.produto = filters.produto[0];

      await this.$store.dispatch("collect_report_data", filters);

      const leads = this.$store.state.report_data;

      this.dados = leads.dados;
      this.headers = leads.headers;

      this.loading = false;
    },
    async prepare_view() {
      let data1 = this.$store.state.leads_disabled;
      let data2 = this.$store.state.leads_enabled;

      if (this.$store.state.consultores.length == 0) {
        await this.$store.dispatch("collect_consultores");
      }

      if (data1.length == 0)
        await this.$store.dispatch("collect_leads_disabled");
      if (data2.length == 0)
        await this.$store.dispatch("collect_leads_enabled");
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];

      setTimeout(() => (this[l] = false), 3000);

      this.loader = null;
    },
  },

  created() {
    this.prepare_view();
  },
};
</script>

<style scoped>
h1 {
  margin-left: 296px;
  color: black;
  font-size: 20px;
  text-transform: uppercase;
  margin-top: 50px;
  position: relative;
  display: block;
}
.h1-underline {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 3px;
  background-color: black;
  margin-bottom: -6px;
}
.v-card {
  margin: 10px 40px 50px 296px;
  padding: 40px;
  padding-top: 10px;
}
.v-card__title {
  margin-bottom: 50px;
}

.v-btn {
  margin-left: 330px !important;
  margin-top: -120px !important;
  width: 70px;
  height: 70px;
  background-color: rgb(246, 147, 30) !important;
}
.v-btn i {
  font-size: 30px !important;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>>
   